import React from "react";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
  TimePicker,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { getGender } from "../utils";

const { Option } = Select;

export default class DrawerPanel extends React.Component {
  state = {
    parentDrawer: false,
    childDrawer: false,
    searchValue: "",
    searchResults: [],
    patientDetail: { name: "", person_id: null },
    defaultDate: moment(new Date(), "DD-MMM-YYYY"),
    defaultTime: moment(new Date(), "HH:mm:ss"),
    date: moment().format("DD-MMM-YYYY"),
    time: moment().format("HH:mm:ss"),
    apt_legacy_id: null,
    doctorId: Number(localStorage.getItem("doctorId")),
    doctors: localStorage.getItem("doctors"),
  };

  onParentClose = () => {
    this.props.setShowModal(false);
  };

  onSearch = (event) => {
    this.setState(
      {
        // searchValue: event.target.value,
        searchValue: event,
      },
      () => this.fetchSearchResults()
    );
  };

  onDateChange = (date, dateString) => {
    this.setState({
      date: dateString,
    });
  };
  onTimeChange = (date, dateString) => {
    this.setState({
      time: dateString,
    });
  };

  fetchSearchResults(value) {
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/v3/person_search?search=${
          this.state.searchValue
        }&queue_page=${0}&page_no=${1}&is_lite=${true}`
      )
      .then((res) => {
        if (res.data.details) {
          this.setState({
            searchResults: res.data.details,
          });
        } else {
          //   openNotification("error", `${res.data.message}`);
          console.log("err", res.data.message);
        }
      });
  }

  postAppointment = () => {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    let fD = new FormData();
    fD.append("person_id", this.state.patientDetail.person_id);
    fD.append("doctor_id", this.state.doctorId);
    // fD.append("time", `${this.state.date} ${this.state.time}`);
    fD.append("time", `${moment(this.state.date).format("YYYY-MM-DD")} ${this.state.time}`);

    // NOTE: adding apt_legacy_id before Hindustan hospital push
    // fD.append("apt_legacy_id", this.state.apt_legacy_id);

    // let timeDifference = moment(this.state.time, "HH:mm A").fromNow(true);
    // is_waiting is 1 if there are no changes to the time
    // NOTE: commenting below code and moving all to waiting by default as suggested by Ravi
    fD.append("is_waiting", 1);
    // if (timeDifference === "a few seconds") {
    // 	fD.append("is_waiting", 1);
    // } else if (timeDifference === "20 minutes") {
    // 	fD.append("is_waiting", 1);
    // } else if (timeDifference === "a minute") {
    // 	fD.append("is_waiting", 1);
    // } else {
    // 	// is_waiting is 0 if changes made to the time
    // 	fD.append("is_waiting", 1);
    // }

    // for (var pair of fD.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    axios
      .post(`${process.env.REACT_APP_API_ROOT}/appointments`, fD, config)
      .then((res) => {
        if (res.data.status) {
          // this.onChildClose();
          this.onParentClose();
          this.props.setRefetchData(true);
        } else {
          message.error("Something went wrong. Please try again");
        }
      });
  };

  disabledDate = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  componentDidMount() {
    this.setState({
      currentDoctorId: localStorage.getItem("doctorId"),
    });
  }

  render() {
    const Options = this.state.searchResults.map((pred) => {
      return (
        <AutoComplete.Option
          key={pred.person_id}
          value={pred.name}
          style={{
            borderBottom: "1px solid #E5E5E5",
            padding: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Avatar src={pred.image} /> */}
            <div style={{ marginLeft: 15 }}>
              <p style={{ marginBottom: 2 }}>{pred.name}</p>
              <p style={{ marginBottom: 2 }}>
                {`${
                  pred?.age ? moment().year() - pred?.age?.split("-")[2] : "0"
                }/${getGender(pred.gender)}`}
                , {pred.number}
              </p>
            </div>

            <Button
              className="button--secondary"
              style={{
                paddingRight: "15px",
                paddingLeft: "15px",
                paddingTop: "0px",
                paddingBottom: "0px",
                borderRadius: "4px",
              }}
            >
              ADD
            </Button>
          </div>
        </AutoComplete.Option>
      );
    });

    return (
      <React.Fragment>
        <Modal
          footer={null}
          closable={false}
          title={
            <div
              style={{
                backgroundColor: "#007AFF10",
                padding: 20,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  marginBottom: 0,
                  color: "#4285F4",
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                New Registration
              </h1>

              <Button
                className="button--primary h40"
                style={{ paddingLeft: 15, paddingRight: 15 }}
                onClick={this.postAppointment}
              >
                Add Queue
              </Button>
            </div>
          }
          visible={this.props.showModal}
          onOk={this.onParentClose}
          onCancel={this.onParentClose}
          destroyOnClose={true}
          bodyStyle={{ maxHeight: 760 }}
          afterClose={() => {
            this.setState({ patientDetail: { name: "", person_id: null } });
          }}
        >
          <div style={{ paddingBottom: 50 }}>
            <h2 style={{ fontSize: 16, fontWeight: 400 }}>
              Search and add patient
            </h2>

            <AutoComplete
              style={{ width: "100%" }}
              onSearch={this.onSearch}
              onChange={(val) => {
                let patient = this.state.searchResults.filter(
                  (res) => res.name === val
                );
                this.setState({ patientDetail: patient[0] });
              }}
              value={this.state?.patientDetail?.name}
              dataSource={Options}
              allowClear
            >
              <Input
                placeholder="Search Patient Name, Mobile, Patient ID"
                suffix={<DownOutlined />}
                className="h40"
                size="large"
              />
            </AutoComplete>

            <div style={{ marginTop: 20 }}>
              <h2 style={{ fontSize: 16, fontWeight: 400 }}>
                Select date and time
              </h2>
              <Row>
                <Col span={10}>
                  <DatePicker
                    defaultValue={this.state.defaultDate}
                    format="DD-MMM-YYYY"
                    onChange={this.onDateChange}
                    disabledDate={this.disabledDate}
                    placeholder=""
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col span={10} offset={1}>
                  <TimePicker
                    onChange={this.onTimeChange}
                    defaultValue={this.state.defaultTime}
                    minuteStep={5}
                    format="HH:mm A"
                    placeholder=""
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </div>

            <div style={{ marginTop: 20 }}>
              <h2 style={{ fontSize: 16, fontWeight: 400 }}>Select doctor</h2>
              <Select
                defaultValue={this.state.doctorId}
                style={{ width: "100%" }}
                size="large"
                onChange={(val) => {
                  this.setState({ doctorId: val });
                }}
              >
                {JSON.parse(this.state.doctors)?.map((doctor) => (
                  <Option value={doctor.doctor_id}>{doctor.doctor_name}</Option>
                ))}
              </Select>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
