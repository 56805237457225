import React from "react";
import DynamicVitals from "../components/dynamicVitals/index";
// import CoMorbidity from "../components/comorbidity";
// import VisitNotes from "../components/visitNotes";
// import Diagnosis from "../components/diagnosis";
import { Anchor } from "antd";

const ThisVisit = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <Anchor
        activeLink="vitals"
        style={{ margin: 16, padding: 32 }}
        onClick={(e, link) => {
          e.preventDefault();
          props.history.push(link.href, {
            state: {
              ...props.location.state.state,
            },
          });
        }}
      >
        <Anchor.Link href="#vitals" title="Vitals" />
        {/*<Anchor.Link href="#symptoms" title="Symptoms" />
        <Anchor.Link href="#diagnosis" title="Diagnosis" />
        <Anchor.Link href="#visitnotes" title="Visit Notes" />*/}
      </Anchor>
      <div id="scrollLayout" style={{ width: "85%" }}>
        {/* Vitals */}
        <div
          style={{
            marginTop: 16,
            background: "#FFFFFF",
            borderRadius: 5,
            padding: 32,
          }}
          id="vitals"
        >
          <DynamicVitals form={props.vitalsForm} {...props} />
        </div>
        {/* SYMPTOMS */}
        {/* <div
          style={{
            marginTop: 16,
            background: "#FFFFFF",
            borderRadius: 5,
            padding: 32,
          }}
          id="symptoms"
        >
          <CoMorbidity form={props.symptomsForm} {...props} category_id={3} />
        </div>*/}
        {/* Diagnosis  */}
         {/* <div
          style={{
            marginTop: 16,
            background: "#FFFFFF",
            borderRadius: 5,
            padding: 32,
          }}
          id="diagnosis"
        >
          <Diagnosis {...props} />
        </div>*/}
        {/* VisitNotes  */}
         {/* <div
          style={{
            marginTop: 16,
            background: "#FFFFFF",
            borderRadius: 5,
            padding: 32,
          }}
          id="Diagnosis"
        >
          <VisitNotes form={props.visitNotesForm} {...props} />
        </div>*/}
      </div>
    </div>
  );
};

export default ThisVisit;
