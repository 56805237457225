import React, { useEffect, useState } from "react";
import { Spin, Timeline } from "antd";
import axios from "axios";
import _ from "lodash";

const PatientSummary = (props) => {
  const [patientDetails, setPatientDetails] = useState([]);
  const [patientVisitHistory, setPatientVisitHistory] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/v2/summary?person_id=${props?.location?.state?.state?.patientId}&detail_view=true`
      )
      .then((res) => {
        let obj = [];

        if (res?.data?.status) {
          res.data.patient_detail.forEach((detail) =>
            detail?.content?.split("\n")?.forEach((x) => {
              let temp = {
                title: x.split(": ")[0],
                content: x.split(": ").slice(1).join(),
              };
              obj.push(temp);
            })
          );
          let timeline = _.groupBy(res?.data?.result, (a) =>
            a.date.substring(3)
          );
          setTimelineData(timeline);
          setPatientDetails(obj);
          setPatientVisitHistory(res?.data?.result);
          setLoading(false);
        } else {
          console.error("something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => console.log("err", err));
  }, [props]);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: 20 }}>
        <div
          style={{
            borderRadius: 5,
            padding: "25px 42px",
            marginBottom: 20,
            background: "#FFFFFF",
          }}
        >
          <h2 style={{ color: "#007AFF", marginBottom: 24 }}>
            Patient Details
          </h2>
          {patientDetails?.map((detail, i) => (
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
              key={i}
            >
              <div style={{ fontSize: 16, marginRight: 5, fontWeight: 500 }}>
                {detail?.title}:
              </div>
              <div style={{ fontSize: 16, color: "#4F4F4F" }}>
                {detail?.content}
              </div>
            </div>
          ))}
        </div>

        <div
          style={{
            borderRadius: 5,
            padding: "25px 42px",
            marginBottom: 20,
            background: "#FFFFFF",
          }}
        >
          {patientVisitHistory ? (
            <div style={{ display: "flex" }}>
              <div style={{ width: "8%", marginRight: 20 }}>
                <Timeline mode="right">
                  {Object.keys(timelineData)?.map((date, i) => (
                    <Timeline.Item key={i}>
                      {date}
                      {timelineData[date].length > 1
                        ? `(${timelineData[date].length})`
                        : null}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>

              <div style={{ width: "calc(92% - 20px)" }}>
                {patientVisitHistory?.map((visit, i) => (
                  <div
                    key={i}
                    style={{
                      border: "1px solid #CADDFF",
                      borderLeftWidth: 5,
                      boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.06)",
                      borderRadius: 8,
                      padding: 24,
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 16,
                        }}
                      >
                        <h2 style={{ margin: 0 }}>{visit?.date}</h2>
                        {visit?.prescription ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={visit?.prescription}
                            style={{ marginLeft: 10 }}
                          >
                            Prescription
                          </a>
                        ) : null}
                      </div>

                      <h2 style={{ fontWeight: "bold" }}>
                        Visited:{" "}
                        <span style={{ fontWeight: 200 }}>{visit?.doctor}</span>
                      </h2>
                    </div>

                    {visit?.summary?.map((summary, i) => {
                      if (summary.title === "Notes") {
                        return (
                          <div style={{ display: "flex" }} key={i}>
                            <div
                              style={{
                                fontSize: 16,
                                marginRight: 5,
                                fontWeight: 500,
                              }}
                            >
                              Visit Notes:
                            </div>
                            <p
                              style={{
                                margin: 0,
                                fontSize: 16,
                                color: "#4F4F4F",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: summary?.content,
                              }}
                            />
                          </div>
                        );
                      }
                      return (
                        <div style={{ display: "flex" }} key={i}>
                          <div
                            style={{
                              fontSize: 16,
                              marginRight: 5,
                              fontWeight: 500,
                            }}
                          >
                            {summary?.content?.split(": ")[0]}:{" "}
                          </div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: 16,
                              color: "#4F4F4F",
                            }}
                          >
                            {summary?.content?.split(": ").slice(1).join()}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Spin>
  );
};

export default PatientSummary;
