import React, { useState } from "react";
import _, { isEmpty } from "lodash";
import { DatePicker, Form, InputNumber } from "antd";
import { openNotification } from "../../shared/notification";
import axios from "axios";

const formItemLayout = {
  colon: false,
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
    lg: {
      span: 15,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
    lg: {
      span: 24,
    },
  },
};

const FormFunction = (props) => {
  const [height, setheight] = useState(null);
  const [weight, setweight] = useState(null);
  const [kitchensink, setkitchensink] = useState(null);

  React.useEffect(() => {
    if (height !== null && weight !== null) {
      setTimeout(() => {
        setBMIValue();
        setBSAValue();
      }, 1000);
    }
  }, [height, weight]);

  const onChangeHandler = (key, value) => {
    setkitchensink({ ...kitchensink, [key]: value });
    if (key === "height") {
      setheight(value);
    }
    if (key === "weight") {
      setweight(value);
    }
  };

  const setBSAValue = () => {
    let surfaceArea = (height * weight) / 3600;
    let bsa = Math.sqrt(surfaceArea);
    let resultingBSA = bsa === 0 ? null : Number(bsa).toFixed(2);
    let ccc = props.vitals.find((x) => x.web_name === "b_s_a");

    let findFeildName = isEmpty(ccc.answers)
      ? `${ccc.web_name}-${ccc.id}`
      : `${ccc.web_name}/${ccc.answers[0].ans_id}-${ccc.id}`;

    props.form.setFieldsValue({
      [`${findFeildName}`]: _.toNumber(resultingBSA),
    });
  };

  const setBMIValue = () => {
    let heightInMts = height / 100;
    let bmi = weight / heightInMts ** 2;
    let resultingBMI = Number(bmi).toFixed(2);

    // find relavant field and assign value
    let ccc = props.vitals.find((x) => x.web_name === "bmi");

    let findFeildName = isEmpty(ccc.answers)
      ? `${ccc.web_name}-${ccc.id}`
      : `${ccc.web_name}/${ccc.answers[0].ans_id}-${ccc.id}`;

    props.form.setFieldsValue({
      [`${findFeildName}`]: _.toNumber(resultingBMI),
    });
  };

  const onFinish = (values) => {
    Object.keys(values).forEach((key) =>
      values[key] === undefined ? delete values[key] : {}
    );

    let patient_answers = [];

    Object.keys(values).forEach((key) => {
      if (key.includes("/")) {
        // UPDATE ANSWER
        patient_answers.push({
          person_ans_id: key.split("/")[1].split("-")[0],
          answers: [
            {
              ans_id: key.split("/")[1].split("-")[0],
              answer:
                values[key] !== null
                  ? key.includes("lmp")
                    ? `${values[key].format("DD MMM YYYY")}`
                    : `${values[key]}`
                  : "",
              is_active: 1,
            },
          ],
        });
      } else {
        // NEW ANSWER
        patient_answers.push({
          qid: key.split("-")[1],
          answers: [
            {
              answer:
                values[key] !== null
                  ? key.includes("lmp")
                    ? `${values[key].format("DD MMM YYYY")}`
                    : `${values[key]}`
                  : "",
            },
          ],
        });
      }
    });

    patient_answers.forEach((ans) => {
      let t = patient_answers.filter(
        (a) => (a.qid || a.person_ans_id) === (ans.qid || ans.person_ans_id)
      );

      if (t.length > 1) {
        let s = t.reduce(
          (acc, curr) => acc + curr?.answers[0]?.answer + "-",
          ""
        );
        s = s.substring(0, s.length - 1);
        let obj = {};

        if (t[0].person_ans_id) {
          obj = {
            person_ans_id: t[0].person_ans_id,
            answers: [
              {
                ans_id: t[0]?.answers[0]?.ans_id,
                answer: s,
                is_active: 1,
              },
            ],
          };
        } else if (t[0].qid) {
          obj = {
            qid: t[0].qid,
            answers: [
              {
                answer: s,
              },
            ],
          };
        }

        patient_answers = patient_answers.filter(
          (a) => (a.qid || a.person_ans_id) !== (ans.qid || ans.person_ans_id)
        );
        patient_answers.push(obj);
      }
    });

    let vitalsBody = {
      category_id: 1,
      appointment_id: props.location.state.state.appointmentId,
      patient_answers: patient_answers,
    };

    axios
      .post(`${process.env.REACT_APP_API_ROOT}/ans_update`, vitalsBody)
      .then((res) => {
        if (res.data.status) {
          openNotification("success", "Vitals saved");
        } else {
          openNotification("error", "Something went wrong");
        }
      });
  };

  const { vitals } = props;

  React.useEffect(() => {
    props.form.setFieldsValue(props.answers);
  }, [props.answers]);

  return (
    <>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        {...formItemLayout}
        labelAlign="left"
        size="middle"
        form={props.form}
        initialValues={props.answers}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {vitals.map((ques, index) => {
            if (
              ques.web_name !== "pefr" &&
              ques.web_name !== "bp" &&
              ques.web_name !== "height" &&
              ques.web_name !== "weight" &&
              ques.web_name !== "lmp"
            ) {
              return (
                <div style={{ width: "30%", margin: "0 8px" }} key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                  >
                    <span style={{ color: "#5D5D5D", fontWeight: "bold" }}>
                      {ques.name}
                    </span>
                    <span style={{ color: "#5D5D5D", opacity: "0.6" }}>
                      ({ques.restrict_value_start} - {ques.restrict_value_end})
                      [{ques.unit}]
                    </span>
                  </div>
                  <Form.Item
                    name={
                      isEmpty(ques.answers)
                        ? `${ques.web_name}-${ques.id}`
                        : `${ques.web_name}/${ques.answers[0].ans_id}-${ques.id}`
                    }
                    rules={[
                      {
                        type: "number",
                        required: false,
                        whitespace: true,
                        min: Number(ques.restrict_value_start),
                        max: Number(ques.restrict_value_end),
                        message: "Please enter correct values",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={
                        ques.web_name === "b_s_a" || ques.web_name === "bmi"
                      }
                      precision={
                        ques.web_name === "b_s_a" || ques.web_name === "bmi"
                          ? 2
                          : undefined
                      }
                      style={{
                        fontWeight: 700,
                        fontSize: 26,
                        color: "#574B61",
                        border: "1px solid #E2E9F4",
                        borderRadius: 2,
                        width: "100%",
                      }}
                      min={Number(ques.restrict_value_start)}
                      max={Number(ques.restrict_value_end)}
                    />
                  </Form.Item>
                </div>
              );
            } else if (ques.web_name === "height") {
              return (
                <div style={{ width: "30%", margin: "0 8px" }} key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                  >
                    <span style={{ color: "#5D5D5D", fontWeight: "bold" }}>
                      {ques.name}
                    </span>
                    <span style={{ color: "#5D5D5D", opacity: "0.6" }}>
                      ({ques.restrict_value_start} - {ques.restrict_value_end})
                      [{ques.unit}]
                    </span>
                  </div>
                  <Form.Item
                    name={
                      isEmpty(ques.answers)
                        ? `${ques.web_name}-${ques.id}`
                        : `${ques.web_name}/${ques.answers[0].ans_id}-${ques.id}`
                    }
                    rules={[
                      {
                        type: "number",
                        required: false,
                        whitespace: true,
                        min: ques.restrict_value_start,
                        max: ques.restrict_value_end,
                        message: "Please enter correct values",
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={onChangeHandler.bind(this, "height")}
                      style={{
                        fontWeight: 700,
                        fontSize: 26,
                        color: "#574B61",
                        border: "1px solid #E2E9F4",
                        borderRadius: 2,
                        width: "100%",
                      }}
                      min={ques.restrict_value_start}
                      max={ques.restrict_value_end}
                    />
                  </Form.Item>
                </div>
              );
            } else if (ques.web_name === "weight") {
              return (
                <div style={{ width: "30%", margin: "0 8px" }} key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                  >
                    <span style={{ color: "#5D5D5D", fontWeight: "bold" }}>
                      {ques.name}
                    </span>
                    <span style={{ color: "#5D5D5D", opacity: "0.6" }}>
                      ({ques.restrict_value_start} - {ques.restrict_value_end})
                      [{ques.unit}]
                    </span>
                  </div>
                  <Form.Item
                    name={
                      isEmpty(ques.answers)
                        ? `${ques.web_name}-${ques.id}`
                        : `${ques.web_name}/${ques.answers[0].ans_id}-${ques.id}`
                    }
                    rules={[
                      {
                        type: "number",
                        required: false,
                        whitespace: true,
                        min: ques.restrict_value_start,
                        max: ques.restrict_value_end,
                        message: "Please enter correct values",
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={onChangeHandler.bind(this, "weight")}
                      style={{
                        fontWeight: 700,
                        fontSize: 26,
                        color: "#574B61",
                        border: "1px solid #E2E9F4",
                        borderRadius: 2,
                        width: "100%",
                      }}
                      min={ques.restrict_value_start}
                      max={ques.restrict_value_end}
                    />
                  </Form.Item>
                </div>
              );
            } else if (ques.web_name === "pefr") {
              return (
                <div style={{ width: "30%", margin: "0 8px" }} key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                  >
                    <span style={{ color: "#5D5D5D", fontWeight: "bold" }}>
                      {ques.name}
                    </span>
                    <span style={{ color: "#5D5D5D", opacity: "0.6" }}>
                      ({ques.restrict_value_start} - {ques.restrict_value_end})
                      [{ques.unit}]
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      name={
                        isEmpty(ques.answers)
                          ? `${ques.web_name}_1-${ques.id}`
                          : `${ques.web_name}_1/${ques.answers[0].ans_id}-${ques.id}`
                      }
                      rules={[
                        {
                          type: "number",
                          required: false,
                          whitespace: true,
                          min: Number(ques.restrict_value_start),
                          max: Number(ques.restrict_value_end),
                          message: "Please enter correct values",
                        },
                      ]}
                    >
                      {/* ADDING COL MAKES VALUE SET IN FORM STATE TO BE STRING INSTEAD OF INT. TYPE: NUMBER IN RULES FAILS LEADING TO ERROR  */}
                      {/* <Col span={6}> */}
                      <InputNumber
                        min={Number(ques.restrict_value_start)}
                        max={Number(ques.restrict_value_end)}
                        stringMode={true}
                        style={{
                          fontWeight: 700,
                          fontSize: 26,
                          color: "#574B61",
                          border: "1px solid #E2E9F4",
                          borderRadius: 2,
                          width: "100%",
                        }}
                        // onChange={(value) => console.log(typeof value, value)}
                      />
                      {/* </Col> */}
                    </Form.Item>

                    <div
                      style={{
                        margin: "0 8px",
                        fontWeight: 700,
                        fontSize: 26,
                      }}
                    >
                      /
                    </div>

                    <Form.Item
                      // label={`${ques.name} (${ques.restrict_value_start} - ${ques.restrict_value_end}) [${ques.unit}]`}
                      name={
                        isEmpty(ques.answers)
                          ? `${ques.web_name}_2-${ques.id}`
                          : `${ques.web_name}_2/${ques.answers[0].ans_id}-${ques.id}`
                      }
                      rules={[
                        {
                          type: "number",
                          required: false,
                          whitespace: true,
                          min: Number(ques.restrict_value_start),
                          max: Number(ques.restrict_value_end),
                          message: "Please enter correct values",
                        },
                      ]}
                    >
                      {/* ADDING COL MAKES VALUE SET IN FORM STATE TO BE STRING INSTEAD OF INT. TYPE: NUMBER IN RULES FAILS LEADING TO ERROR  */}
                      {/* <Col span={6}> */}
                      <InputNumber
                        min={Number(ques.restrict_value_start)}
                        max={Number(ques.restrict_value_end)}
                        stringMode={true}
                        style={{
                          fontWeight: 700,
                          fontSize: 26,
                          color: "#574B61",
                          border: "1px solid #E2E9F4",
                          borderRadius: 2,
                          width: "100%",
                        }}
                        // onChange={(value) => console.log(typeof value, value)}
                      />
                      {/* </Col> */}
                    </Form.Item>

                    <div
                      style={{
                        margin: "0 8px",
                        fontWeight: 700,
                        fontSize: 26,
                      }}
                    >
                      /
                    </div>

                    <Form.Item
                      // label={`${ques.name} (${ques.restrict_value_start} - ${ques.restrict_value_end}) [${ques.unit}]`}
                      name={
                        isEmpty(ques.answers)
                          ? `${ques.web_name}_3-${ques.id}`
                          : `${ques.web_name}_3/${ques.answers[0].ans_id}-${ques.id}`
                      }
                      rules={[
                        {
                          type: "number",
                          required: false,
                          whitespace: true,
                          min: Number(ques.restrict_value_start),
                          max: Number(ques.restrict_value_end),
                          message: "Please enter correct values",
                        },
                      ]}
                    >
                      {/* ADDING COL MAKES VALUE SET IN FORM STATE TO BE STRING INSTEAD OF INT. TYPE: NUMBER IN RULES FAILS LEADING TO ERROR  */}
                      {/* <Col span={6}> */}
                      <InputNumber
                        min={Number(ques.restrict_value_start)}
                        max={Number(ques.restrict_value_end)}
                        stringMode={true}
                        style={{
                          fontWeight: 700,
                          fontSize: 26,
                          color: "#574B61",
                          border: "1px solid #E2E9F4",
                          borderRadius: 2,
                          width: "100%",
                        }}
                        // onChange={(value) => console.log(typeof value, value)}
                      />
                      {/* </Col> */}
                    </Form.Item>
                  </div>
                </div>
              );
            } else if (ques.web_name === "bp") {
              return (
                <div style={{ width: "30%", margin: "0 8px" }} key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                  >
                    <span style={{ color: "#5D5D5D", fontWeight: "bold" }}>
                      {ques.name}
                    </span>
                    <span style={{ color: "#5D5D5D", opacity: "0.6" }}>
                      ({ques.min_value.split("/")[0]} -{" "}
                      {ques.max_value.split("/")[0]} /{" "}
                      {ques.min_value.split("/")[1]} -{" "}
                      {ques.max_value.split("/")[1]}) [{ques.unit}]
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      name={
                        isEmpty(ques.answers)
                          ? `${ques.web_name}_1-${ques.id}`
                          : `${ques.web_name}_1/${ques.answers[0].ans_id}-${ques.id}`
                      }
                      rules={[
                        {
                          type: "number",
                          required: false,
                          whitespace: true,
                          min: Number(ques.min_value.split("/")[0]),
                          max: Number(ques.max_value.split("/")[0]),
                          message: "Please enter correct values",
                        },
                      ]}
                    >
                      {/* ADDING COL MAKES VALUE SET IN FORM STATE TO BE STRING INSTEAD OF INT. TYPE: NUMBER IN RULES FAILS LEADING TO ERROR  */}
                      {/* <Col span={8}> */}
                      <InputNumber
                        min={Number(ques.restrict_value_start)}
                        max={Number(ques.restrict_value_end)}
                        stringMode={true}
                        style={{
                          fontWeight: 700,
                          fontSize: 26,
                          color: "#574B61",
                          border: "1px solid #E2E9F4",
                          borderRadius: 2,
                          width: "100%",
                        }}
                      />
                      {/* </Col> */}
                    </Form.Item>

                    <div
                      style={{
                        margin: "0 8px",
                        fontWeight: 700,
                        fontSize: 26,
                      }}
                    >
                      /
                    </div>

                    <Form.Item
                      name={
                        isEmpty(ques.answers)
                          ? `${ques.web_name}_2-${ques.id}`
                          : `${ques.web_name}_2/${ques.answers[0].ans_id}-${ques.id}`
                      }
                      rules={[
                        {
                          type: "number",
                          required: false,
                          whitespace: true,
                          min: Number(ques.min_value.split("/")[1]),
                          max: Number(ques.max_value.split("/")[1]),
                          message: "Please enter correct values",
                        },
                      ]}
                    >
                      {/* ADDING COL MAKES VALUE SET IN FORM STATE TO BE STRING INSTEAD OF INT. TYPE: NUMBER IN RULES FAILS LEADING TO ERROR  */}
                      {/* <Col span={8}> */}
                      <InputNumber
                        min={Number(ques.restrict_value_start)}
                        max={Number(ques.restrict_value_end)}
                        style={{
                          fontWeight: 700,
                          fontSize: 26,
                          color: "#574B61",
                          border: "1px solid #E2E9F4",
                          borderRadius: 2,
                          width: "100%",
                        }}
                        stringMode={true}
                      />
                      {/* </Col> */}
                    </Form.Item>
                  </div>
                </div>
              );
            } else if (ques.web_name === "lmp") {
              return (
                <div style={{ width: "30%", margin: "0 8px" }} key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                  >
                    <span style={{ color: "#5D5D5D", fontWeight: "bold" }}>
                      {ques.name}
                    </span>
                  </div>
                  <Form.Item
                    name={
                      isEmpty(ques.answers)
                        ? `${ques.web_name}-${ques.id}`
                        : `${ques.web_name}/${ques.answers[0].ans_id}-${ques.id}`
                    }
                    rules={[
                      {
                        // type: "string",
                        required: false,
                        message: "Please enter correct values",
                      },
                    ]}
                  >
                    <DatePicker
                      className="vitals-datepicker"
                      format="DD MMM YYYY"
                    />
                  </Form.Item>
                </div>
              );
            }
          })}
        </div>
        {/*<Row gutter={16} style={{ marginLeft: 8 }}>*/}
        {/*  <Form.Item>*/}
        {/*    <Button*/}
        {/*      type="primary"*/}
        {/*      htmlType="submit"*/}
        {/*      className="button--primary h40"*/}
        {/*    >*/}
        {/*      Submit*/}
        {/*    </Button>*/}
        {/*  </Form.Item>*/}
        {/*</Row>*/}
      </Form>
    </>
  );
};

export default FormFunction;
