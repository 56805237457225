import React, { Component, lazy, Suspense, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { Layout, Menu, Tag } from "antd";
import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import packageJson from "../package.json";
import ErrorBoundary from "./shared/errorBoundary";
import Newlanding from "./pages/newlanding";
import PatientView from "./pages/patientView";

const Auth = lazy(() => import(/* webpackChunkName: 'Auth' */ "./pages/auth"));

const NewPatientRegistrationModal = lazy(() =>
  import(
    /* webpackChunkName: 'NewPatientRegistrationModal' */ "./components/NewPatientRegistrationModal"
  )
);

axios.interceptors.request.use(
  function (config) {
    config.headers.token = localStorage.token;
    // config.headers["Authorization"] = `Bearer ${localStorage.token}`;
    return config;
  },
  function (error) {
    // openNotification("error", `Something went wrong`);
    console.log("error", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // if (response.status === 401) {
    //   //TODO: API returns "token Missing" for other things too. So can't use response interceptor for route gaurding
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
    return response;
  },
  function (error) {
    if (error.message.includes("401")) {
      // localStorage.clear();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const ProtectedRoutes = (props) => {
  let history = useHistory();
  if (!localStorage.token) {
    // TODO remove this before prod
    history.push(`/`);
    return props.children;
  } else {
    return props.children;
  }
};

function RenderMenu(props) {
  const [selectedMenu, setSelectedMenu] = useState([]);
  let history = useHistory();
  let location = useLocation();

  function handleClick({ item, key, keyPath, domEvent }) {
    if (key === "logout") {
      props.onLogout();
      localStorage.clear();
      history.push(`/`);
    } else {
      setSelectedMenu([key]);
      history.push(`/${key}`);
    }
  }

  return (
    <Layout.Header>
      {location.pathname !== "/" ? (
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={({ item, key, keyPath, domEvent }) =>
            handleClick({ item, key, keyPath, domEvent })
          }
          selectedKeys={selectedMenu}
        >
          <Menu.Item key="landing">
            <div className="logo"></div>
          </Menu.Item>

          <Menu.SubMenu
            key="sub1"
            icon={<UserOutlined />}
            title={
              <>
                {localStorage.getItem("display_name")} <DownOutlined />
              </>
            }
            style={{ float: "right" }}
          >
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
            <Menu.Item key="version" disabled>
              <Tag>v{packageJson.version}</Tag>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      ) : null}
    </Layout.Header>
  );
}

export const ClinicContext = React.createContext();
ClinicContext.displayName = "ClinicContext";

export default class Main extends Component {
  onLogout = () => {
    this.setState({
      hospital_title: "",
      responsibility: [],
      reports: [],
      reportsResponsibility: false,
    });
  };

  render() {
    return (
      <main style={{ minHeight: "100vh" }}>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <ClinicContext.Provider value={this.state}>
                <Layout
                  style={{ minHeight: "100vh", backgroundColor: "#FAFAFA" }}
                >
                  <RenderMenu onLogout={this.onLogout} />
                  <ErrorBoundary {...this.props}>
                    <Layout.Content
                      style={{
                        margin: "0rem",
                        overflow: "auto",
                      }}
                    >
                      <Route exact path="/" component={Auth} />
                      <ProtectedRoutes>
                        <Route exact path="/landing" component={Newlanding} />
                        <Route
                          exact
                          path="/patientView/:patient_id"
                          component={PatientView}
                        />
                        <Route
                          exact
                          path="/addpatient"
                          component={NewPatientRegistrationModal}
                          hideAudit
                        />
                      </ProtectedRoutes>
                    </Layout.Content>
                  </ErrorBoundary>
                </Layout>
              </ClinicContext.Provider>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </main>
    );
  }
}
