import { Button, Col, Form, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import PatientProfile from "../views/patientProfile";
import PatientSummary from "../components/patientSummary";
import ThisVisit from "../views/thisVisit";
// import PrescriptionWithTemplates from "../views/prescription";
import PrintingPrescription from "../views/printPrescription";
import { getGender } from "../utils";
import moment from "moment";

export default function PatientView(props) {
  const [currentPage, setCurrentPage] = useState("patientSummary");
  const [responsibilities, setResponsibilites] = useState(19);

  const [profileForm] = Form.useForm();
  const [coMorbiditiesForm] = Form.useForm();
  const [vitalsForm] = Form.useForm();
  const [symptomsForm] = Form.useForm();
  const [visitNotesForm] = Form.useForm();

  useEffect(() => {
    setResponsibilites(JSON.parse(localStorage.getItem("responsibility")));
  }, []);

  let shouldRunEffect = responsibilities === null;

  useEffect(() => {
    if (shouldRunEffect) {
      axios
        .get(`${process.env.REACT_APP_API_ROOT}/user_responsibility`)
        .then((res) => {
          setResponsibilites(res?.data?.responsibility?.responsibility);
          localStorage.setItem(
            "responsibility",
            JSON.stringify(res?.data?.responsibility?.responsibility)
          );
          // if (shouldEnable("patientSummary")) {
          //   setCurrentPage("patientSummary");
          // }
        })

        .catch((err) => console.error(err));
    }
  }, [shouldRunEffect]);

  // const shouldEnable = (id) => {
  //   const findObj = responsibilities.find((resp) => resp.id === id);
  //   return typeof findObj === "object" ? true : false;
  // };

  return (
    <React.Fragment>
      <div
        style={{
          fontFamily: "Roboto",
          fontWeight: 500,
          fontSize: 24,
          color: "#231F20",
        }}
      >
        {props.location.state.state.patientName},{" "}
        {moment().year() -
          props?.location?.state?.state?.patientAge.split("-")[2]}
        /{getGender(props?.location?.state?.state?.gender)}
        <Tabs
          size="large"
          defaultActiveKey="profile"
          onChange={(key) => setCurrentPage(key)}
          activeKey={currentPage}
          tabBarExtraContent={
            <React.Fragment>
              {currentPage === "profile" && (
                <Button
                  className="button--primary h40"
                  style={{ paddingLeft: 45, paddingRight: 45 }}
                  onClick={() => {
                    profileForm.submit();
                    coMorbiditiesForm.submit();
                  }}
                >
                  Submit
                </Button>
              )}
              {currentPage === "thisVisit" && (
                <Button
                  className="button--primary h40"
                  style={{ paddingLeft: 45, paddingRight: 45 }}
                  onClick={() => {
                    vitalsForm.submit();
                    symptomsForm.submit();
                    visitNotesForm.submit();
                  }}
                >
                  Submit
                </Button>
              )}
            </React.Fragment>
          }
        >
          <Tabs.TabPane tab="Profile" key="profile" />
          <Tabs.TabPane tab="Patient Summary" key="patientSummary" />
          <Tabs.TabPane tab="This Visit" key="thisVisit" />
           {/*<Tabs.TabPane tab="Prescription" key={12} />*/}
          <Tabs.TabPane tab="Printing Prescription" key={13} />
        </Tabs>
      </div>
      <Row gutter={[8, 24]}>
        {/*<Col span={4}>*/}
        {/*  <Card bodyStyle={{ padding: 0 }} className="userResponsibilities" style={{ width: "90%" }}>*/}
        {/*    <div*/}
        {/*      className={`${*/}
        {/*        currentPage === "profile" ? "selected" : "resp"*/}
        {/*      }`}*/}
        {/*      onClick={() => setCurrentPage("profile")}*/}
        {/*    >*/}
        {/*      <span>Profile</span>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={`patientView--section ${*/}
        {/*        currentPage === "patientSummary" ? "selected" : "resp"*/}
        {/*      }`}*/}
        {/*      onClick={() => setCurrentPage("patientSummary")}*/}
        {/*    >*/}
        {/*      <span>Patient Summary</span>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={`patientView--section ${*/}
        {/*        currentPage === "thisVisit" ? "selected" : "resp"*/}
        {/*      }`}*/}
        {/*      onClick={() => setCurrentPage("thisVisit")}*/}
        {/*    >*/}
        {/*      <span>This Visit</span>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={`patientView--section ${*/}
        {/*        currentPage === "12" ? "selected" : "resp"*/}
        {/*      }`}*/}
        {/*      onClick={() => setCurrentPage("12")}*/}
        {/*    >*/}
        {/*      <span>Prescription</span>*/}
        {/*    </div>*/}
        {/*    /!* <div*/}
        {/*      className={`patientView--section ${*/}
        {/*        currentPage === 19 ? "selected" : "resp"*/}
        {/*      }`}*/}
        {/*      onClick={() => setCurrentPage(19)}*/}
        {/*    >*/}
        {/*      <span>Templates</span>*/}
        {/*    </div> *!/*/}
        {/*    <div*/}
        {/*      className={`patientView--section ${*/}
        {/*        currentPage === "13" ? "selected" : "resp"*/}
        {/*      }`}*/}
        {/*      onClick={() => setCurrentPage("13")}*/}
        {/*    >*/}
        {/*      <span>Printing Prescription</span>*/}
        {/*    </div>*/}
        {/*  </Card>*/}
        {/*</Col>*/}
        <Col span={24}>
          {/* PatientProfile */}
          {currentPage === "profile" ? (
            <PatientProfile
              {...props}
              profileForm={profileForm}
              coMorbiditiesForm={coMorbiditiesForm}
            />
          ) : null}
          {/* PatientSummary */}
          {currentPage === "patientSummary" ? (
            <PatientSummary {...props} />
          ) : null}
          {/* ThisVisit */}
          {currentPage === "thisVisit" ? (
            <ThisVisit
              vitalsForm={vitalsForm}
              symptomsForm={symptomsForm}
              visitNotesForm={visitNotesForm}
              {...props}
            />
          ) : null}
          {/* {currentPage === "12" ? (
             <PrescriptionWithTemplates {...props} />
           ) : null}*/}
          {currentPage === "13" ? <PrintingPrescription {...props} /> : null}
        </Col>
      </Row>
    </React.Fragment>
  );
}
