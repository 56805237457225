import React, { Component } from "react";
import axios from "axios";
import { Spin } from "antd";
import "./printPrescription.css";
import empty_prescription from "../assets/empty_prescription.png";
import PrintPrescriptionModal from "../components/PrintPrescriptionModal";

export default class PrintPrescription extends Component {
  state = {
    loading: false,
    prescription: null,
    prescriptions: [],
    isPrintPrescriptionModalOpen: false,
    selected_pdf: "",
  };

  fetchPrescription = (person_id) => {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/v2/responsibility_summary?responsibility_id=13&person_id=${person_id}`
      )
      .then((res) => {
        this.setState({
          prescriptions: res?.data?.summary?.app_list || [],
          loading: false,
        });
      });
  };

  componentDidMount() {
    document.title = "Prescription - UHI";
    this.fetchPrescription(this.props.location.state.state.patientId);
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <div style={{ margin: "32px 16px" }}>
          {this.state.prescriptions.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <img
                src={empty_prescription}
                style={{ width: "25%", margin: "5rem auto" }}
                alt="No Prescriptions"
              />
              <h2>No Prescription found</h2>
            </div>
          ) : (
            <div className="prescriptions--list">
              {this.state.prescriptions.map((prescription, i) => (
                <div
                  key={i}
                  className="prescription"
                  onClick={() =>
                    this.setState({
                      isPrintPrescriptionModalOpen: true,
                      selected_pdf: prescription.answer,
                    })
                  }
                >
                  <embed
                    src={prescription.answer}
                    type="application/pdf"
                    className="prescription--pdf"
                    height="182px"
                    width="150px"
                    id={prescription.appointment_id}
                  />
                  <div className="prescription--details">
                    {prescription.date}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <PrintPrescriptionModal
          open={this.state.isPrintPrescriptionModalOpen}
          setOpen={(value) =>
            this.setState({ isPrintPrescriptionModalOpen: value })
          }
          pdf={this.state.selected_pdf}
        />
      </Spin>
    );
  }
}
