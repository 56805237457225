import React, { useEffect, useState } from "react";
import axios from "axios";
import { filter } from "lodash";
import { openNotification } from "../shared/notification";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import PatientAppointments from "../components/allPatients";
import NewRegistrationModal from "../components/NewPatientRegistrationModal";
import moment from "moment";
import { SearchIcon } from "../shared/images";
import { getGender } from "../utils";
import { SwapOutlined } from "@ant-design/icons";

let doctors = JSON.parse(localStorage.getItem("doctors"));
const filters = [
  {
    label: "Waiting List",
    value: "1",
  },
  {
    label: "Appointments",
    value: "4",
  },  
  {
    label: "Review",
    value: "3",
  },
  {
    label: "Completed",
    value: "0",
  },
];

export default function Newlanding(props) {

  let defaultDoctors = "";

  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [showPatientsModal, setShowPatientsModal] = useState(false);
  const [showNewRegistrationModal, setShowNewRegistrationModal] =
    useState(false);
  const [refetchData, setRefetchData] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(""+localStorage.getItem("queueType"));
  const [selectedDoctor, setSelectedDoctor] = useState(localStorage.getItem("doctorIndex"));


  if (!doctors){
    doctors = JSON.parse(localStorage.getItem("doctors"));
  }

  if (doctors){
    defaultDoctors = doctors[selectedDoctor]?.doctor_name;
  }

  // STATUS TAG ACTIVE / NON-ACTIVE STYLES
  const getStatusClass = (record) => {
    if (record === "1") {
      return {
        backgroundColor: "#F2FFFA",
        color: "#00B466",
        border: "none",
        borderRadius: "3px",
        padding: "7px",
      };
    }
    return {
      backgroundColor: "#DBDBDB",
      color: "#4A4A4A",
      border: "none",
      borderRadius: "3px",
      padding: "7px",
    };
  };

  useEffect(() => {
    if (searchText) {
      let filteredPatients = filter(
        patients,
        (patient) =>
          patient.name.toLowerCase().includes(searchText.toLowerCase()) ||
          `${patient.id}`.includes(searchText.toLowerCase()) ||
          `${patient.apt_legacy_id || ""}`.includes(searchText.toLowerCase())
      );
      setFilteredData(filteredPatients);
    } else {
      setFilteredData(patients);
    }
  }, [searchText]);

  const changeVisitStatus = (visit_id, status) => {
    const requestBody = {
      changed_appointments: [
        {
          q_id: visit_id,
          state: status,
        },
      ],
    };
    axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/v3/appointments_switch`,
        requestBody
      )
      .then((res) => {
        if (res.data.status) {
          openNotification("success", "Status updated successfully");
          setRefetchData(true);
        } else {
          openNotification("success", "Please try again!");
        }
      })
      .catch((err) => {
        console.error(err);
        openNotification("success", "Please try again!");
      });
  };

  const redirectToPatientView = (record, rowIndex) => {
    return {
      onClick: (event) => {
        props.history.push(`/patientView/${record.id}`, {
          state: {
            patientId: record.person_id,
            appointmentId: record.id,
            patientName: record.name,
            patientAge: record.age,
            avatar: record.image_new,
            gender: record.gender,
          },
        });
      }, // click row
    };
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: false,
      align: "left",
      onCell: redirectToPatientView,
      render: (cell, record) => (
        <div>
          <div style={{ textTransform: "uppercase" }}>{record?.name}</div>
          <div style={{ color: "#757575", fontSize: "0.75em" }}>{record?.phone_number}, {record?.place}</div>
        </div>
      ),
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: false,
      align: "left",
      onCell: redirectToPatientView,
      render: (cell, record) => (
        <span>{`${moment().year() - cell.split("-")[2]}/${getGender(
          record.gender
        )}`}</span>
      ),
    },
    {
      title: "Previous appointment",
      dataIndex: "previous_appointment",
      sorter: false,
      align: "left",
      onCell: redirectToPatientView,
      render: (cell) => (
        <span>
          {cell == null ? "New Patient" : moment().format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      title: "Status tag",
      dataIndex: "status_tag",
      sorter: false,
      align: "left",
      onCell: redirectToPatientView,
      render: (cell, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="statusTag--wrapper">
            <Tag style={getStatusClass(record.vitals)}>Vitals</Tag>
            <Tag style={getStatusClass(record.history)}>History</Tag>
            <Tag style={getStatusClass(record.diagnosis)}>Diagnosis</Tag>
            <Tag style={getStatusClass(record.prescription)}>Prescription</Tag>
          </div>
          {record.critical_on && (
            <div
              style={{
                fontSize: 10,
                color: "red",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  backgroundColor: "red",
                  marginRight: 8,
                }}
              />
              {record.critical_on}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      width: 200,
      render: (text, record) => {
        const menus = (
          <Menu>
            {selectedFilter === "0" && (
              <Menu.Item onClick={() => changeVisitStatus(record.id, 1)}>
                <span>Move to waiting list</span>
              </Menu.Item>
            )}
            {selectedFilter === "1" && (
              <Menu.Item onClick={() => changeVisitStatus(record.id, 2)}>
                <span>Move to Completed</span>
              </Menu.Item>
            )}

            {selectedFilter === "3" && (
              <Menu.Item onClick={() => changeVisitStatus(record.id, 1)}>
                <span>Move to waiting list</span>
              </Menu.Item>
            )}
          </Menu>
        );
        return (
          <Dropdown overlay={menus} trigger={["hover"]}>
            <SwapOutlined
              style={{
                fontSize: 28,
                transform: "rotateZ(90deg)",
              }}
            />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    if (doctors){
      localStorage.setItem("clinicId", doctors[selectedDoctor].clinic_id);
      localStorage.setItem("doctorId", doctors[selectedDoctor].doctor_id);
      localStorage.setItem("doctorName", doctors[selectedDoctor].doctor_name);
      localStorage.setItem("doctorIndex", selectedDoctor);
    }
    setRefetchData(true);
  }, [selectedDoctor]);



  useEffect(() => {
    setLoading(true);
    setRefetchData(false);
    let date = localStorage.getItem("date");
    let clinicId = localStorage.getItem("clinicId");
    let sessionId = null;
    localStorage.setItem("queueType", ""+selectedFilter);


    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/v4/appointments?date=${date}&clinic_id=${clinicId}&queueType=${selectedFilter}&sessionId=${sessionId}`
      )
      .then((res) => {
        if (res.data.status) {
          if (selectedFilter === "0") {
            setPatients(res.data.completed);
            setFilteredData(res.data.completed);
          }
          if (selectedFilter === "1") {
            setPatients(res.data.waiting);
            setFilteredData(res.data.waiting);
          }
          if (selectedFilter === "3") {
            setPatients(res.data.review_patient);
            setFilteredData(res.data.review_patient);
          }

          if (selectedFilter === "4") {
            setPatients(res.data.appointments);
            setFilteredData(res.data.appointments);
          }
          setLoading(false);
        } else {
          openNotification("error", `${res.data.message}`);
          setLoading(false);
        }
      });
  }, [selectedFilter, refetchData]);

  return (
    <Space direction="vertical" size={40} style={{ width: "100%" }}>
      <Row align="middle" justify="space-between" gutter={16}>
        <Col span={3} style={{ textAlign: "left" }}>
          <Typography.Title
            level={3}
            style={{ marginBottom: 0 }}
            className="tblue tmedium"
          >
            Waiting List{" "}
          </Typography.Title>
        </Col>
        <Col span={7}>
          <Input
            size="large"
            style={{
              border: "none",
              height: 48,
            }}
            className="tmedium"
            placeholder="Search with Name, IP number, Patient ID"
            onChange={({ target }) => setSearchText(target.value)}
            prefix={<SearchIcon style={{ marginRight: 10 }} />}
          />
        </Col>
        <Col span={4} style={{ textAlign: "left" }}>
          <Select
            style={{
              height: 48,
              width: "100%",
            }}
            size="large"
            className="tmedium tgreen select--noBorder select--greenChevron select--h48"
            defaultValue={selectedFilter}
            placeholder="Filter by status"
            onChange={(value) => setSelectedFilter(value)}
          >
            {filters.map((filt, filtInd) => {
              return (
                <Select.Option value={filt.value} key={filtInd}>
                  {filt.label}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={4} style={{ textAlign: "left" }}>
          <Select
            style={{
              height: 48,
              width: "100%",
            }}
            size="large"
            className="tmedium tgreen select--noBorder select--greenChevron select--h48"
            defaultValue={defaultDoctors}
            placeholder="Filter by status"
            onChange={(value) => setSelectedDoctor(value)}
          >
            {doctors?.map((filt, filtInd) => {
              return (
                <Select.Option value={filtInd} key={filtInd}>
                  {filt.doctor_name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={6}  style={{ textAlign: "right" }}>
          <Button
            onClick={() => setShowNewRegistrationModal(true)}
            className="button--secondary h40"
            style={{ marginRight: 20 }}
          >
            New Registration
          </Button>
          <Button
            onClick={() => setShowPatientsModal(true)}
            className="button--primary h40"
          >
            Add to Queue
          </Button>
        </Col>
      </Row>

      <Table
        loading={loading}
        size="default"
        className="landing--patientList"
        rowClassName="table--clickableRow"
        columns={columns}
        dataSource={filteredData}
        // TODO: ADD PAGINATION
        pagination={false}
        // pagination={
        //   this.state.count > 0
        //     ? {
        //         total: this.state.count,
        //         pageSize: 10,
        //         current: this.state.page,
        //         showSizeChanger: false,
        //       }
        //     : false
        // }
      />

      {showPatientsModal && (
        <PatientAppointments
          showModal={showPatientsModal}
          setShowModal={setShowPatientsModal}
          setRefetchData={setRefetchData}
        />
      )}

      {showNewRegistrationModal && (
        <NewRegistrationModal
          showModal={showNewRegistrationModal}
          setShowModal={setShowNewRegistrationModal}
          setRefetchData={setRefetchData}
        />
      )}
    </Space>
  );
}
