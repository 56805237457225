// TO DO: Handle error messages. Limit age, appointment datepicker
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  AutoComplete,
  Button,
  Calendar,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import { openNotification } from "../../shared/notification";
import { CalendarOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const dateFormat = "DD-MM-YYYY";

export default function NewPatientRegistrationModal(props) {
  const [allergies, setAllergies] = useState([]);
  const [places, setPlaces] = useState([]);
  const [dobType, setDobType] = useState("date");
  const [dob, setDob] = useState("");
  const [customAge, setCustomAge] = useState("");
  const [customAgeRange, setCustomAgeRange] = useState("years");
  const [displayAge, setDisplayAge] = useState("");

  const [form] = Form.useForm();

  const doctors = localStorage.getItem("doctors");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/allergy_list`, {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        let arr = res.data?.list?.map((t) => t.name);
        setAllergies(arr);
      })
      .catch((err) => console.log("err: ", err));
  }, []);

  const disablePastDates = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  const disableFutureDates = (current) => {
    return current && current > moment();
  };

  const onParentClose = () => {
    props.setShowModal(false);
  };

  const confirmAge = () => {
    if (dobType === "custom") {
      let dob = moment().subtract(customAge, customAgeRange);
      form.setFieldsValue({ dob: dob.format(dateFormat) });
      setDisplayAge(dob.format(dateFormat));
      setDob(dob.format(dateFormat));
    } else {
      form.setFieldsValue({ dob });
      setDisplayAge(dob);
    }
  };

  const onSearch = (value) => {
    axios
      .get(
        encodeURI(
          `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}`
        )
      )
      .then((res) =>
        setPlaces(
          res.data.predictions.map((prediction) => prediction.description)
        )
      );
  };

  const onFinish = (values) => {
    let reqBody = {
      phone_number: [
        {
          name: "",
          number: values.mobileno,
        },
      ],
      basic_info: {
        initial: values.initial,
        salutation: values.salutation,
        name: values.firstName,
        last_name: values.lastName,
        age: dob,
        age_type: dobType === "date" ? 1 : 0,
        doctor_reference: values.referedby,
        sex: values.gender,
        descriptive_notes: values.descNotes,
        legacy_id: values.legacyId,
        doctor_note: values.doctor_note,
        allergy: values.allergy ? values.allergy.join(",") : "",
      },
      addresses: [
        {
          city: values.city,
        },
      ],
      emergency: [
        {
          name: values.emergencyName,
          number: values.emergencyNumber,
        },
      ],
      appointment: {
        doctor_id: values.consultingDoctor,
        time: `${moment(values.appointment.date).format("YYYY-MM-DD")} ${moment(
          values.appointment.time
        ).format("HH:mm:ss")}`,
        is_waiting: 1,
      },
    };

    axios
      .post(`${process.env.REACT_APP_API_ROOT}/v2/registration`, reqBody)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          if (props.mode !== "edit") {
            openNotification("success", "Patient Registered");
            props.setRefetchData(true);
            onParentClose();
          } else {
            openNotification("success", "Patient Data Saved");
          }
        } else {
          openNotification("error", "Something went wrong, please try again");
          // console.log("Error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const salutations = [
    "Mr.",
    "Dr.",
    "Mrs.",
    "Miss.",
    "Ms.",
    "Master.",
    "Baby.",
    "Mx.",
  ];

  const options = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Other" },
  ];

  return (
    <Modal
      footer={null}
      closable={false}
      title={
        <div
          style={{
            backgroundColor: "#007AFF10",
            padding: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              marginBottom: 0,
              color: "#4285F4",
              fontSize: 24,
              fontWeight: 500,
            }}
          >
            New Registration
          </h1>

          <Button
            className="button--primary h40"
            style={{ paddingLeft: 45, paddingRight: 45 }}
            // onClick={this.postAppointment}
            onClick={() => form.submit()}
          >
            Create
          </Button>
        </div>
      }
      visible={props.showModal}
      onOk={onParentClose}
      onCancel={onParentClose}
      destroyOnClose={true}
      bodyStyle={{ maxHeight: 860 }}
      width={1000}
    >
      <Form
        form={form}
        name="discharge"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        labelAlign="left"
        size="middle"
        initialValues={{
          appointment: {
            date: moment(),
            time: moment(),
          },
          consultingDoctor: Number(localStorage.getItem("doctorId")),
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              name="consultingDoctor"
              label="Consulting doctor"
              rules={[
                {
                  required: true,
                  message: "Please choose the consulting doctor",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                // defaultValue="Dr."
                // onChange={this.referedbySalutationChange}
                size="large"
              >
                {JSON.parse(doctors)?.map((doctor) => (
                  <Select.Option value={doctor.doctor_id}>
                    {doctor.doctor_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="salutation"
              label="Salutation"
              rules={[
                { required: true, message: "Please select a salutation" },
              ]}
              style={{ width: "35%", minWidth: 100 }}
            >
              <Select
                // defaultValue="Mr."
                name="salutation"
                // onChange={this.salutationChange}
                size="large"
              >
                {salutations.map((salutation, index) => (
                  <Select.Option key={index} value={salutation}>
                    {salutation}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the patient's first name",
                },
              ]}
            >
              <Input
                // value={this.state.initial}
                // placeholder="First name"
                // onChange={this.inputChange}
                className="h40"
              />
            </Form.Item>

            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: true,
                  message: "Please select the patient's gender",
                },
              ]}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={options}
                size="large"
                className="radiogroup--padding49"
              />
            </Form.Item>

            <div className="ant-form-item-label">
              <label className="ant-form-item-required">Age</label>
            </div>

            <Popconfirm
              overlayClassName="age-popconfirm"
              placement="bottom"
              okText="Submit"
              cancelText="Cancel"
              onConfirm={confirmAge}
              icon={null}
              title={[
                <div style={{ padding: "12px 0px" }}>
                  <div style={{ textAlign: "center" }}>
                    <Radio.Group
                      onChange={(e) => setDobType(e.target.value)}
                      defaultValue="date"
                      buttonStyle="solid"
                    >
                      <Radio.Button value="date">Date of Birth</Radio.Button>
                      <Radio.Button value="custom">Custom</Radio.Button>
                    </Radio.Group>
                  </div>
                  <Row>
                    {dobType === "date" ? (
                      <div style={{ width: 300 }}>
                        <Calendar
                          fullscreen={false}
                          defaultValue={moment()}
                          value={dob ? moment(dob, dateFormat) : moment()}
                          onChange={(date) => setDob(date.format(dateFormat))}
                          disabledDate={disableFutureDates}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 300,
                          height: 321,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <InputNumber
                          value={customAge}
                          onChange={(value) => setCustomAge(value)}
                          style={{ marginBottom: 24 }}
                        />
                        <Radio.Group
                          onChange={(e) => setCustomAgeRange(e.target.value)}
                          value={customAgeRange}
                          buttonStyle="solid"
                        >
                          <Radio.Button value="days">Days</Radio.Button>
                          <Radio.Button value="weeks">Weeks</Radio.Button>
                          <Radio.Button value="months">Months</Radio.Button>
                          <Radio.Button value="years">Years</Radio.Button>
                        </Radio.Group>
                      </div>
                    )}
                  </Row>
                </div>,
              ]}
            >
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: 2,
                  background: "#ffffff",
                  fontSize: 14,
                  padding: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "35%",
                  minWidth: 100,
                }}
              >
                <span>{displayAge}</span>
                <CalendarOutlined />
              </div>
            </Popconfirm>

            <Form.Item
              name="dob"
              rules={[
                { required: true, message: "Please enter the patient's DOB" },
              ]}
              className="hidden-form-item"
            >
              <Input style={{ display: "none" }} />
            </Form.Item>

            <Form.Item
              name="mobileno"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  pattern: /^[0-9\b]+$/,
                  message: "Invalid mobile number",
                },
              ]}
            >
              <InputNumber className="h40" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="city"
              label="City / Town"
              rules={[{ required: true, message: "Please enter a city" }]}
            >
              <AutoComplete
                style={{ width: "100%" }}
                onSearch={onSearch}
                dataSource={places.map((place, i) => (
                  <AutoComplete.Option key={i} value={place}>
                    {place}
                  </AutoComplete.Option>
                ))}
                allowClear
                className="h40"
              >
                <Input className="h40" />
              </AutoComplete>
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Row>
                <Col span={10}>
                  <Form.Item
                    name={["appointment", "date"]}
                    label="Appointment"
                    rules={[
                      {
                        required: true,
                        message: "Please choose the appointment date",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD-MMM-YYYY"
                      disabledDate={disablePastDates}
                      placeholder=""
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={10} offset={1}>
                  <Form.Item
                    name={["appointment", "time"]}
                    label=" "
                    rules={[
                      {
                        required: true,
                        message: "Please choose the appointment time",
                      },
                    ]}
                    className="antd--form--hideRequired"
                  >
                    <TimePicker
                      minuteStep={5}
                      format="HH:mm A"
                      placeholder=""
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Form.Item
              name="referedby"
              label="Referral doctor"
              rules={[{ required: false }]}
            >
              <Input
                // defaultValue={this.state.referedby}
                // placeholder="Referred by"
                // onChange={this.inputChange}
                className="h40"
              />
            </Form.Item>

            <Form.Item
              name="initial"
              label="Initial"
              rules={[{ required: false }]}
              style={{ width: "35%" }}
            >
              <Input
                // value={this.state.initial}
                // placeholder="Initial"
                name="initial"
                // onChange={this.inputChange}
                className="h40"
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: false }]}
            >
              <Input
                // value={this.state.initial}
                // placeholder="Last name"
                // onChange={this.inputChange}
                className="h40"
              />
            </Form.Item>

            <Form.Item
              name="legacyId"
              label="Legacy ID"
              rules={[{ required: false }]}
            >
              <Input
                // value={this.state.initial}
                // placeholder="Last name"
                // onChange={this.inputChange}
                className="h40"
              />
            </Form.Item>

            <Form.Item
              name="emergencyName"
              label="Emergency contact name"
              rules={[{ required: false }]}
            >
              <Input
                // placeholder="Emergency contact name"
                name="emergencyName"
                // onChange={this.inputChange}
                // value={this.state.emergencyName}
                className="h40"
              />
            </Form.Item>

            <Form.Item
              name="emergencyNumber"
              label="Emergency contact number"
              rules={[
                {
                  required: false,
                  pattern: /^[0-9\b]+$/,
                  message: "Invalid mobile number",
                },
              ]}
            >
              <Input
                // placeholder="Emergency contact number"
                name="emergencyNumber"
                // onChange={this.inputChange}
                // value={this.state.emergencyName}
                className="h40"
              />
            </Form.Item>

            <Form.Item
              name="allergy"
              label="Allergy"
              rules={[{ required: false }]}
              style={{ width: "100%" }}
            >
              <Select mode="multiple" size="large">
                {allergies?.map((allergy) => (
                  <Select.Option value={allergy}>{allergy}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="doctor_note"
              label="Doctor's Notes"
              rules={[{ required: false }]}
            >
              {/* <Input
                  placeholder="Descriptive notes - optional"
                  name="descNotes"
                  // onChange={this.inputChange}
                  // value={this.state.descNotes}
                /> */}

              <TextArea rows={4} placeholder="Type here" name="descNotes" />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Row> */}
      </Form>
    </Modal>
  );
}
