import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";

const PrintPrescriptionModal = (props) => {
  return (
    <Modal
      visible={props.open}
      width="850px"
      height="600px"
      onCancel={() => props.setOpen(false)}
      footer={null}
      title={null}
      closable={false}
    >
      <div>
        {props.pdf && (
          <iframe
            title="PDF"
            src={props.pdf}
            width="812px"
            height="600px"
            id="pdf-embed"
          />
        )}
        {props.html && (
          <iframe
            title="PDF"
            srcDoc={props.html}
            width="812px"
            height="600px"
            id="pdf-embed"
          />
        )}
      </div>
    </Modal>
  );
};

PrintPrescriptionModal.defaultProps = {
  open: false,
  setOpen: () => {},
  pdf: "",
  html: "",
};

PrintPrescriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  pdf: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

export default PrintPrescriptionModal;
