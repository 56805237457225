import React from "react";
import { Anchor } from "antd";
import PatientProfileForm from "../components/PatientProfile";
// import CoMorbidity from "../components/comorbidity";

const PatientProfile = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <Anchor
        activeLink="patient-profile"
        style={{ margin: 16, padding: 32 }}
        onClick={(e, link) => {
          e.preventDefault();
          props.history.push(link.href, {
            state: {
              ...props.location.state.state,
            },
          });
        }}
      >
        <Anchor.Link href="#patient-profile" title="Patient Profile" />
        {/*<Anchor.Link href="#co-morbidities" title="Co-Morbidities" />*/}
      </Anchor>
      <div id="scrollLayout" style={{ width: "100%" }}>
        <div
          style={{
            marginTop: 16,
            background: "#FFFFFF",
            borderRadius: 5,
            padding: 32,
          }}
          id="patient-profile"
        >
          <PatientProfileForm form={props.profileForm} {...props} />
        </div>
        {/* SYMPTOMS */}
        {/*<div
          style={{
            marginTop: 16,
            background: "#FFFFFF",
            borderRadius: 5,
            padding: 32,
          }}
          id="co-morbidities"
        >
          <CoMorbidity
            form={props.coMorbiditiesForm}
            {...props}
            category_id={4}
          />
        </div>*/}
      </div>
    </div>
  );
};

export default PatientProfile;
