// TO DO: Handle error messages. Limit age, appointment datepicker
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  AutoComplete,
  Calendar,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import { openNotification } from "../shared/notification";
import { CalendarOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const dateFormat = "DD-MM-YYYY";

export default function PatientProfile(props) {
  const [editID, setEditID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allergies, setAllergies] = useState([]);
  const [places, setPlaces] = useState([]);
  const [dobType, setDobType] = useState("date");
  const [dob, setDob] = useState("");
  const [customAge, setCustomAge] = useState("");
  const [customAgeRange, setCustomAgeRange] = useState("days");
  const [displayAge, setDisplayAge] = useState("");
  const [phoneId, setPhoneId] = useState("");
  const [addressId, setAddressId] = useState("");

  const doctors = localStorage.getItem("doctors");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/v2/profile/${props?.location?.state?.state?.patientId}`
      )
      .then((res) => {
        if (res.data?.status) {
          let patient_profile = res.data?.profile;

          let obj = {
            consultingDoctor: Number(localStorage.getItem("doctorId")),
            referedbySalutation: patient_profile?.basic_info?.doctor_reference
              ? patient_profile?.basic_info?.doctor_reference.split(" ")[0]
              : "",
            salutation: patient_profile?.basic_info?.salutation,
            firstName: patient_profile?.basic_info?.name,
            gender: patient_profile?.basic_info?.sex,
            dob: moment(patient_profile?.basic_info?.age, dateFormat),
            mobileno: patient_profile?.phone_number[0]?.number,
            referedby: patient_profile?.basic_info?.doctor_reference
              ? patient_profile?.basic_info?.doctor_reference
                  .split(" ")
                  .splice(1)
                  .join(" ")
              : "",
            city: patient_profile?.addresses[0]?.city,
            initial: patient_profile?.basic_info?.initial,
            lastName: patient_profile?.basic_info?.last_name,
            emergencyName: patient_profile?.emergency[0]?.name,
            emergencyNumber: patient_profile?.emergency[0]?.number,
            doctor_note: patient_profile?.basic_info?.doctor_note,
            allergy: patient_profile?.basic_info?.allergy
              ? patient_profile?.basic_info?.allergy.split(",")
              : [],
          };

          setAddressId(patient_profile?.addresses[0]?.id)
          setPhoneId(patient_profile?.phone_number[0]?.id)
          setEditID(patient_profile?.basic_info?.id);
          props.form.setFieldsValue(obj);
          setDob(patient_profile?.basic_info?.age);
          setDisplayAge(patient_profile?.basic_info?.age);
          setLoading(false);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => console.log("Error: ", err));
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/allergy_list`, {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        let arr = res.data?.list?.map((t) => t.name);
        setAllergies(arr);
      })
      .catch((err) => console.log("err: ", err));
  }, []);

  const disablePastDates = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  const disableFutureDates = (current) => {
    return current && current > moment();
  };

  const confirmAge = () => {
    if (dobType === "custom") {
      let dob = moment().subtract(customAge, customAgeRange);
      props.form.setFieldsValue({ dob: dob.format(dateFormat) });
      setDisplayAge(dob.format(dateFormat));
      setDob(dob.format(dateFormat));
    } else {
      props.form.setFieldsValue({ dob });
      setDisplayAge(dob);
    }
  };

  const onSearch = (value) => {
    axios
      .get(
        encodeURI(
          `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}`
        )
      )
      .then((res) =>
        setPlaces(
          res.data.predictions.map((prediction) => prediction.description)
        )
      );
  };

  const onFinish = (values) => {
    let reqBody = {
      phone_number: [
        {
          id: phoneId,
          number: values.mobileno,
        },
      ],
      basic_info: {
        id: editID,
        initial: values.initial || "",
        salutation: values.salutation,
        name: values.firstName,
        last_name: values.lastName || "",
        age: moment(values.dob, dateFormat).format(dateFormat),
        doctor_reference: values.referedby || "",
        sex: values.gender,
        descriptive_notes: values.doctor_note || "",
        legacy_id: values.legacyId || "",
        doctor_note: values.doctor_note || "",
        allergy: values.allergy ? values.allergy.join(",") : "",
      },
      addresses: [
        {
          id: addressId,
          city: values.city,
        },
      ],
      emergency: [
        {
          name: values.emergencyName || "",
          number: values.emergencyNumber || "",
        },
      ],
      // appointment: {
      // doctor_id: values.consultingDoctor || "",
      // time: `${moment(values.appointment.date).format("YYYY-MM-DD")} ${moment(
      //   values.appointment.time
      // ).format("HH:mm:ss")}`,
      // is_waiting: 1,
      // },
    };

    axios
      .post(`${process.env.REACT_APP_API_ROOT}/v2/registration`, reqBody)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          openNotification("success", "Patient Data Saved");
        } else {
          openNotification("error", "Something went wrong, please try again");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        openNotification("error", "Something went wrong, please try again");
      });
  };
  const salutations = [
    "Mr.",
    "Dr.",
    "Mrs.",
    "Miss.",
    "Ms.",
    "Master.",
    "Baby.",
    "Mx.",
  ];

  const options = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Other" },
  ];

  return (
    <Spin spinning={loading}>
      <Form
        form={props.form}
        name="discharge"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        labelAlign="left"
        size="middle"
        initialValues={null}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              name="consultingDoctor"
              label="Consulting doctor"
              rules={[
                {
                  required: true,
                  message: "Please choose the consulting doctor",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select size="large">
                {JSON.parse(doctors)?.map((doctor, i) => (
                  <Select.Option value={doctor.doctor_id} key={i}>
                    {doctor.doctor_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="salutation"
              label="Salutation"
              rules={[
                { required: true, message: "Please select a salutation" },
              ]}
              style={{ width: "35%", minWidth: 100 }}
            >
              <Select name="salutation" size="large">
                {salutations.map((salutation, index) => {
                  return (
                    <Select.Option key={index} value={salutation}>
                      {salutation}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the patient's first name",
                },
              ]}
            >
              <Input className="h40" />
            </Form.Item>

            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: true,
                  message: "Please select the patient's gender",
                },
              ]}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={options}
                size="large"
                className="radiogroup--padding49"
              />
            </Form.Item>

            <div className="ant-form-item-label">
              <label className="ant-form-item-required">Age</label>
            </div>

            <Popconfirm
              overlayClassName="age-popconfirm"
              placement="bottom"
              okText="Submit"
              cancelText="Cancel"
              onConfirm={confirmAge}
              icon={null}
              title={[
                <div style={{ padding: "12px 0px" }}>
                  <div style={{ textAlign: "center" }}>
                    <Radio.Group
                      onChange={(e) => setDobType(e.target.value)}
                      defaultValue="date"
                      buttonStyle="solid"
                    >
                      <Radio.Button value="date">Date of Birth</Radio.Button>
                      <Radio.Button value="custom">Custom</Radio.Button>
                    </Radio.Group>
                  </div>
                  <Row>
                    {dobType === "date" ? (
                      <div style={{ width: 300 }}>
                        <Calendar
                          fullscreen={false}
                          defaultValue={moment()}
                          value={dob ? moment(dob, dateFormat) : moment()}
                          onChange={(date) => setDob(date.format(dateFormat))}
                          disabledDate={disableFutureDates}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 300,
                          height: 321,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <InputNumber
                          value={customAge}
                          onChange={(value) => setCustomAge(value)}
                          style={{ marginBottom: 24 }}
                        />
                        <Radio.Group
                          onChange={(e) => setCustomAgeRange(e.target.value)}
                          value={customAgeRange}
                          buttonStyle="solid"
                        >
                          <Radio.Button value="days">Days</Radio.Button>
                          <Radio.Button value="weeks">Weeks</Radio.Button>
                          <Radio.Button value="months">Months</Radio.Button>
                          <Radio.Button value="years">Years</Radio.Button>
                        </Radio.Group>
                      </div>
                    )}
                  </Row>
                </div>,
              ]}
            >
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: 2,
                  background: "#ffffff",
                  fontSize: 14,
                  padding: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "35%",
                  minWidth: 100,
                }}
              >
                <span>{displayAge}</span>
                <CalendarOutlined />
              </div>
            </Popconfirm>

            <Form.Item
              name="dob"
              rules={[
                { required: true, message: "Please enter the patient's DOB" },
              ]}
              className="hidden-form-item"
            >
              <Input style={{ display: "none" }} />
            </Form.Item>

            <Form.Item
              name="mobileno"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  pattern: /^[0-9\b]+$/,
                  message: "Invalid mobile number",
                },
              ]}
            >
              <InputNumber className="h40" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="city"
              label="City / Town"
              rules={[{ required: true, message: "Please enter a city" }]}
            >
              <AutoComplete
                style={{ width: "100%" }}
                onSearch={onSearch}
                dataSource={places.map((place, i) => (
                  <AutoComplete.Option key={i} value={place}>
                    {place}
                  </AutoComplete.Option>
                ))}
                allowClear
                className="h40"
              >
                <Input className="h40" />
              </AutoComplete>
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Form.Item
              name="referedby"
              label="Referral doctor"
              rules={[{ required: false }]}
            >
              <Input className="h40" />
            </Form.Item>

            <Form.Item
              name="initial"
              label="Initial"
              rules={[{ required: false }]}
              style={{ width: "35%" }}
            >
              <Input name="initial" className="h40" />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: false }]}
            >
              <Input className="h40" />
            </Form.Item>

            <Form.Item
              name="legacyId"
              label="Legacy ID"
              rules={[{ required: false }]}
            >
              <Input className="h40" />
            </Form.Item>

            <Form.Item
              name="emergencyName"
              label="Emergency contact name"
              rules={[{ required: false }]}
            >
              <Input name="emergencyName" className="h40" />
            </Form.Item>

            <Form.Item
              name="emergencyNumber"
              label="Emergency contact number"
              rules={[
                {
                  required: false,
                  pattern: /^[0-9\b]+$/,
                  message: "Invalid mobile number",
                },
              ]}
            >
              <Input name="emergencyNumber" className="h40" />
            </Form.Item>

            <Form.Item
              name="allergy"
              label="Allergy"
              rules={[{ required: false }]}
              style={{ width: "100%" }}
            >
              <Select mode="multiple" size="large">
                {allergies?.map((allergy, i) => (
                  <Select.Option value={allergy} key={i}>
                    {allergy}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="doctor_note"
              label="Doctor's Notes"
              rules={[{ required: false }]}
            >
              <TextArea rows={4} placeholder="Type here" name="doctor_note" />
            </Form.Item>
          </Col>
        </Row>
        {/*<Button*/}
        {/*  className="button--primary h40"*/}
        {/*  style={{ paddingLeft: 45, paddingRight: 45 }}*/}
        {/*  onClick={() => props.form.submit()}*/}
        {/*>*/}
        {/*  Submit*/}
        {/*</Button>*/}
      </Form>
    </Spin>
  );
}
