import React, { Component } from "react";
import WrappedDynamicRule from "./formFunction";
import { isEmpty } from "lodash";
import axios from "axios";
import { Spin } from "antd";
import moment from "moment";

export default class DynamicVitals extends Component {
  state = {
    vitals: [],
    answers: [],
    loading: true,
  };
  fetchData = (categoryId, appointmentId, clinicId) => {
    let promise1 = axios.get(
      `${process.env.REACT_APP_API_ROOT}/v4/getQuestions?category_id=${categoryId}&appointment_id=${appointmentId}&clinic_id=${clinicId}`
    );

    let promise2 = axios.get(
      `${process.env.REACT_APP_API_ROOT}/v4/getQuestions?category_id=${categoryId}&appointment_id=${appointmentId}&clinic_id=${clinicId}&is_answer=1`
    );

    Promise.all([promise1, promise2])
      .then((values) => {
        let questions = values[0].data.question_list.questions;
        let answers = values[1].data.question_list.questions;
        let obj = {};

        answers.forEach((ans) => {
          let t = questions.find((t) => t.id === ans.id);

          if (isEmpty(t.answers)) {
            if (t.web_name === "bp") {
              obj[`${t.web_name}_1-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[0]
              );
              obj[`${t.web_name}_2-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[1]
              );
            } else if (t.web_name === "pefr") {
              obj[`${t.web_name}_1-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[0]
              );
              obj[`${t.web_name}_2-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[1]
              );
              obj[`${t.web_name}_3-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[2]
              );
            } else if (
              t.web_name === "bmi" ||
              t.web_name === "b_s_a" ||
              t.web_name === "fev1"
            ) {
              obj[`${t.web_name}-${t.id}`] = parseFloat(
                ans?.answers[0]?.answer
              );
            } else if (t.web_name === "lmp") {
              obj[`${t.web_name}-${t.id}`] = ans?.answers[0]?.answer
                ? moment(ans?.answers[0]?.answer, "DD MMM YYYY")
                : "";
            } else {
              obj[`${t.web_name}-${t.id}`] = parseInt(ans?.answers[0]?.answer);
            }
          } else {
            if (t.web_name === "bp") {
              obj[`${t.web_name}_1/${t.answers[0].ans_id}-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[0]
              );
              obj[`${t.web_name}_2/${t.answers[0].ans_id}-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[1]
              );
            } else if (t.web_name === "pefr") {
              obj[`${t.web_name}_1/${t.answers[0].ans_id}-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[0]
              );
              obj[`${t.web_name}_2/${t.answers[0].ans_id}-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[1]
              );
              obj[`${t.web_name}_3/${t.answers[0].ans_id}-${t.id}`] = parseInt(
                ans?.answers[0]?.answer.split("-")[2]
              );
            } else if (
              t.web_name === "bmi" ||
              t.web_name === "b_s_a" ||
              t.web_name === "fev1"
            ) {
              obj[`${t.web_name}/${t.answers[0].ans_id}-${t.id}`] = parseFloat(
                ans?.answers[0]?.answer
              );
            } else if (t.web_name === "lmp") {
              obj[`${t.web_name}/${t.answers[0].ans_id}-${t.id}`] = ans
                ?.answers[0]?.answer
                ? moment(ans?.answers[0]?.answer, "DD MMM YYYY")
                : "";
            } else {
              obj[`${t.web_name}/${t.answers[0].ans_id}-${t.id}`] = parseInt(
                ans?.answers[0]?.answer
              );
            }
          }
        });

        this.setState({ vitals: values[0].data.question_list.questions });
        this.setState({ answers: obj });
        this.setState({ loading: false });
      })
      .catch((err) => console.log("Error: ", err));
  };

  componentDidMount() {
    document.title = "Vitals - UHI";
    this.fetchData(
      1,
      this.props.location.state.state.appointmentId,
      localStorage.getItem("clinicId")
    );
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <h2 style={{ fontSize: 24, color: "#979797" }}>VITALS</h2>
        <WrappedDynamicRule
          vitals={this.state.vitals}
          answers={this.state.answers}
          {...this.props}
        />
      </Spin>
    );
  }
}
