import React from "react";
import "antd/dist/antd.css";
import "./App.css";
import Main from "./main";
import useConnectionStatus from "check-connection";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function App(props) {
  return (
    <ConnectionStatus {...props}>
      <Main />
    </ConnectionStatus>
  );
}

export default App;

function ConnectionStatus(props) {
  let connected = useConnectionStatus();
  return (
    <React.Fragment>
      <Spin
        spinning={!connected}
        size="large"
        tip="Internet connection lost. Trying to reconnect..."
        delay={500}
        indicator={<LoadingOutlined />}
      >
        <div className={`App ${!connected ? "networkDisconnected" : null}`}>
          {props.children}
        </div>
      </Spin>
    </React.Fragment>
  );
}
